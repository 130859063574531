import * as THREE from 'three'
export class ThreeTest {
  shaderTest(container) {
    let vs = `
    attribute vec3 a_position;
    attribute vec2 a_uv;

    varying vec2 v_uv;
    varying vec3 v_position;

    void main() {
        v_uv = a_uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(a_position, 1.0);
        v_position = gl_Position.xyz;
    }
    `

    let fs = `
    precision mediump float;
    varying vec2 v_uv;
    varying vec3 v_position;
    uniform float scale;
    uniform float angle;

    void main() {
      float stripeWidth = scale * 1.0;
      mat3 matrix = mat3(
        vec3(cos(angle) ,sin(angle),0),
        vec3(-sin(angle),cos(angle) ,0),
        vec3(0,0,1)
      );
      vec3 pp = matrix * vec3(v_position.xy, 0.0);
      float stripeIndexX = floor(pp.x / stripeWidth);
      float stripeIndexY = floor(pp.y / stripeWidth);
      bool isStripeX = mod(stripeIndexX, 2.0) < 1.0;
      bool isStripeY = mod(stripeIndexY, 2.0) < 1.0;
      vec3 stripeColor = (isStripeX && isStripeY) ? vec3(1.0, 0.0, 1.0) : vec3(0.0, 0.0, 0.0);
      gl_FragColor = vec4(stripeColor, 1.0);
    }
    `

    // 创建渲染器
    const renderer = new THREE.WebGLRenderer()
    // renderer.setSize(container.clientWidth, container.clientHeight)
    renderer.setSize(800, 800)
    container.appendChild(renderer.domElement)

    // 创建场景
    const scene = new THREE.Scene()

    // 创建相机
    // const camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, 1000)
    let w = 800
    let h = 800
    // const camera = new THREE.OrthographicCamera(-w / 2, w / 2, h / 2, -h / 2, 0.1, 1000)
    // const camera = new THREE.OrthographicCamera(-10, 10, 10, -10, 0.1, 1000)
    const camera = new THREE.OrthographicCamera(-10, 10, 10, -10, 0.1, 1000)
    camera.position.z = 5

    let group = new THREE.Group()

    // 创建geo

    // const vertices = new Float32Array([-100.0, 0.0, 0.0, 100.0, 0.0, 0.0, 0.0, 100.0, 0.0])
    const vertices = new Float32Array([0.0, 0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 1.0, 0.0, 0.0, 1.0, 0.0])
    // const vertices = new Float32Array([-1.0, 0.0, 0.0, 1.0, 0.0, 0.0, 0.0, 1.0, 0.0])
    const indices = [0, 1, 2, 0, 2, 3]
    const texCoords = new Float32Array([0.0, 0.0, 1.0, 0.0, 0.5, 1.0])

    let geometry = new THREE.BufferGeometry()
    geometry.setIndex(indices)
    geometry.setAttribute('a_position', new THREE.BufferAttribute(vertices, 3))
    geometry.setAttribute('a_uv', new THREE.BufferAttribute(texCoords, 2))

    // 创建材料
    var material = new THREE.ShaderMaterial({
      vertexShader: vs,
      fragmentShader: fs,
      uniforms: {
        lineColor: { value: new THREE.Vector4(1, 0, 0, 0) },
        lineSpacing: { value: 1.0 },
        lineThickness: { value: 1.0 },
        scale: { value: 0.015 },
        // angle: { value: Math.PI / 4 },
        angle: { value: 0 },
      },
    })

    // 创建mesh
    let mesh = new THREE.Mesh(geometry, material)

    group.add(mesh)

    // 场景添加group
    scene.add(group)

    // 渲染循环
    function animate() {
      requestAnimationFrame(animate)

      // 旋转立方体
      // group.rotation.x += 1.11
      // group.rotation.y += 1.11

      // 渲染场景
      renderer.render(scene, camera)
    }

    animate()
  }

  test1(container) {
    // 获取容器元素
    // const container = this.$refs.container
    // const container = document.getElementById('container')

    // 创建渲染器
    const renderer = new THREE.WebGLRenderer()
    // renderer.setSize(container.clientWidth, container.clientHeight)
    renderer.setSize(800, 800)
    container.appendChild(renderer.domElement)

    // 创建场景
    const scene = new THREE.Scene()

    // 创建相机
    const camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, 1000)
    camera.position.z = 5

    let group = new THREE.Group()
    // 创建立方体
    for (let i = 0; i < 10; i++) {
      let geometry = new THREE.BoxGeometry(Math.random() * 2, Math.random(), Math.random())
      let material = new THREE.MeshBasicMaterial({ color: 0x00ff00 })
      let mesh = new THREE.Mesh(geometry, material)
      group.add(mesh)
    }
    scene.add(group)

    // 渲染循环
    function animate() {
      requestAnimationFrame(animate)

      // 旋转立方体
      // group.rotation.x += 1.11
      // group.rotation.y += 1.11

      // 渲染场景
      renderer.render(scene, camera)
    }

    animate()
  }
}

// export let mThreeTest = new ThreeTest()
