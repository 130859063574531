<template>
  <div class="app-container">
    <div ref="container" width="800" height="800"></div>
  </div>
</template>

<script>
import * as THREE from "three";

import { ThreeTest } from "./three_test";

export default {
  components: {},
  filters: {},
  model: {},
  data() {
    return {};
  },
  mounted() {
    // this.createScene()
    this.testShader();
  },
  created() {},
  methods: {
    testShader() {
      let t = new ThreeTest();
      t.shaderTest(this.$refs.container);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  background-color: #f4f4f4;
  // justify-content: space-between;
}

.el-button {
  width: 77px;
  height: 28px;
  border-radius: 4px;
  padding: 0px 0px;
  font-size: 12px;
  text-align: center;
}

.box-card {
  width: 327px;
  min-height: 1024px;
  background: #ffffff;
  border: 1px solid #dddddd;
  margin-left: 10px;
  margin-right: 0px;
  margin-top: 30px;
  padding: 21px 21px 21px 11px;
  .row {
    display: flex;
    flex-direction: row;
    // margin-top: 40px;
  }
  .row-two {
    // margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  /deep/ .el-select__caret {
    color: #a3a3a3 !important;
  }
  /deep/ .el-input__suffix {
    top: 3px;
    right: 0px;
  }
  /deep/ .el-input__icon {
    line-height: inherit;
  }

  /deep/ .el-input__suffix-inner {
    display: inline-block;
  }
  /deep/ .el-input__inner {
    height: 27px;
    max-height: 30px !important;
    line-height: 30px;
    font-size: 12px !important;
    background: #fff !important;
    padding: 5px 10px !important;
    background-color: #fff !important;
    border-radius: 5px 0px 0px 5px;
  }
}

.axis-container {
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-template-rows: 30px 1fr;
  padding: 0px 0px 0px 0px;
  .ruler-x {
    grid-row-start: 1;
    grid-column-start: 2;
  }
  .ruler-y {
    grid-row-start: 2;
    grid-column-start: 1;
  }
  .axis {
    grid-row-start: 2;
    grid-column-start: 2;
    background-color: black;
  }
}

.table_select {
  padding: 0px;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  border: 1px solid #dddddd;
  // display: flex;
  .table_select_left {
    display: inline-block;
    width: 40%;
    margin: 6px 10px;
  }
  .table_select_right {
    display: inline-block;
    width: 55%;
    text-align: right;
    .table_select__btnGroup {
      cursor: pointer;
      font-size: 12px;
      margin-right: 20px;
      color: #0c7de6;
    }
  }
  .table_select__btn {
    padding: 1px 6px;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 2px;
    margin-right: 4px;
    font-size: 12px;
  }
}

.import-dialog {
  /deep/ .upload-demo {
    height: 30px;
    display: flex;
    flex-direction: row;
  }

  /deep/.el-dialog {
    // width: 550px;
    // height: 182px;
    background: #ffffff;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__body {
    padding: 19px 10px 42px 14px;
  }
  .dialog-body {
    height: 30px;
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  /deep/ .el-select__caret {
    color: #a3a3a3 !important;
  }
  /deep/ .el-input__suffix {
    top: 3px;
    right: 0px;
  }
  /deep/ .el-input__icon {
    line-height: inherit;
  }

  /deep/ .el-input__suffix-inner {
    display: inline-block;
  }
  /deep/ .el-input__inner {
    height: 27px;
    max-height: 30px !important;
    line-height: 30px;
    font-size: 12px !important;
    background: #fff !important;
    padding: 5px 10px !important;
    background-color: #fff !important;
    border-radius: 5px 0px 0px 5px;
  }
}

.bit-dialog {
  /deep/.el-dialog {
    // width: 550px;
    // height: 182px;
    background: #ffffff;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/.el-dialog__body {
    padding: 19px 10px 42px 14px;
  }
  .dialog-body {
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  /deep/ .el-input-number {
    width: 168px;
    height: 23px;
  }
  /deep/ .el-input {
    width: 168px;
    height: 23px;
    line-height: 21px;
    font-size: 11px;
  }
  /deep/ .el-input__inner {
    width: 168px;
    height: 23px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
  }
}

// /deep/ .el-input-number {
//   width: 143px;
//   height: 26px;
// }
// /deep/ .el-input {
//   width: 143px;
//   height: 26px;
//   line-height: 24px;
//   font-size: 11px;
// }
// /deep/ .el-input__inner {
//   width: 143px;
//   height: 26px;
//   background: #ffffff !important;
//   border: 1px solid #dddddd;
//   border-radius: 2px;
// }

.rule-dialog {
  /deep/.el-dialog {
    width: 792px;
    height: 663px;
    background: #ffffff;
    border: 1px solid #dddddd;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border-radius: 4px;
  }
  /deep/ .el-dialog__header {
    width: 793px;
    height: 41px;
    background: #fff4f4;
  }
  /deep/ .el-dialog__body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 0px 0px 0px;
  }

  /deep/ .el-input-number {
    width: 143px;
    height: 26px;
  }
  /deep/ .el-input {
    width: 143px;
    height: 26px;
    line-height: 24px;
    font-size: 11px;
  }
  /deep/ .el-input__inner {
    width: 143px;
    height: 26px;
    background: #ffffff !important;
    border: 1px solid #dddddd;
    border-radius: 2px;
  }
  /deep/ .el-select__caret {
    color: #a3a3a3 !important;
  }
  /deep/ .el-input__suffix {
    top: 2px;
    right: 0px;
  }
  /deep/ .el-input__icon {
    line-height: inherit;
  }

  /deep/ .el-input__suffix-inner {
    display: inline-block;
  }
  /deep/ .el-input__inner {
    height: 27px;
    max-height: 30px !important;
    line-height: 30px;
    font-size: 12px !important;
    background: #fff !important;
    padding: 5px 10px !important;
    background-color: #fff !important;
    text-align: left;
    border-radius: 5px 0px 0px 5px;
  }

  // /deep/ .el-dialog__footer {
  //   height: 20px;
  //   margin-bottom: 20px;
  // }

  .rule {
    width: 466px;
    height: 533px;
    background: #f4f4f4;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-left: 20px;
    margin-top: 19px;
    display: flex;
    flex-direction: column;

    .form-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px 11px 0px 11px;
    }

    .form-table {
      display: flex;
      flex-direction: column;
      padding: 0px 11px 0px 11px;
      height: 65%;
      .table {
        width: 100%;
        height: 100%;
        margin-top: 20px;
        background: #ffffff;
        border: 1px solid #dddddd;
        /deep/ .table_header {
          background: #e9eff3 !important;
        }
        /deep/ .el-table__cell {
          padding: 3px !important;
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          background: #f4f4f4;
        }
        .disabled {
          color: #b2b2b2 !important;
        }

        /deep/ .el-input-number {
          width: 69px;
          height: 23px;
        }
        /deep/ .el-input {
          width: 69px;
          height: 23px;
          line-height: 21px;
          font-size: 11px;
        }
        /deep/ .el-input__inner {
          width: 69px;
          height: 23px;
        }
      }
    }
  }
  .image {
    width: 275px;
    height: 533px;
    background: #f4f4f4;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-right: 21px;
    margin-top: 19px;

    display: flex;
    justify-content: center;
  }
}
.log-dialog {
  /deep/.el-dialog {
    width: 736px;
    height: 486px;
    background: #ffffff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.19);
    border: 1px solid #dddddd;
    border-radius: 4px;
  }
  /deep/ .el-dialog__header {
    width: 793px;
    height: 41px;
    background: #fff4f4;
  }
  /deep/ .el-dialog__body {
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    padding: 0px 0px 0px 0px;
  }
  .el-header,
  .el-footer {
    background-color: #b3c0d1;
    color: #333;
    text-align: center;
    height: 30px !important;
    line-height: 30px;
  }

  .el-aside {
    background-color: #d3dce6;
    color: #333;
    text-align: center;
    line-height: 200px;
  }

  .el-main {
    background-color: #e9eef3;
    color: #333;
    // text-align: center;
    line-height: 15px;
  }

  body > .el-container {
    margin-bottom: 40px;
    flex-direction: column;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
}
</style>
